const assetUrl0 = require('vectors/phone-documents.svg');
const assetUrl1 = require('vectors/phone-selfie.svg');
const assetUrl2 = require('vectors/404/sign-illustration.svg');
const assetUrl3 = require('vectors/logos/quantic-logo-footer.svg');
const assetUrl4 = require('vectors/icons/apple-footer-icon.svg');
const assetUrl5 = require('vectors/icons/android-footer-icon.svg');
const assetUrl6 = require('vectors/quantic-public-security-filing-number-logo.svg');
const assetUrl7 = require('vectors/icons/expand-footer-mobile-icon.svg');
const assetUrl8 = require('vectors/icons/close-footer-mobile-icon.svg');
const assetUrl9 = require('vectors/icons/download-on-the-app-store-button.svg');
const assetUrl10 = require('vectors/icons/get-it-on-google-play-button.svg');
const assetUrl11 = require('vectors/logos/quantic-logo-full-color.svg');
const assetUrl12 = require('vectors/logos/quantic-logo-white.svg');
const assetUrl13 = require('vectors/logos/quantic-logo-color.svg');
const assetUrl14 = require('vectors/icons/chevron-icon.svg');
const assetUrl15 = require('vectors/icons/chevron-grey-icon.svg');
const assetUrl16 = require('vectors/icons/close-menu-icon.svg');
const assetUrl17 = require('vectors/icons/hamburger-menu-icon.svg');
const assetUrl18 = require('vectors/icons/hamburger-menu-pink-icon.svg');
const assetUrl19 = require('vectors/icons/right-arrow-pink-icon.svg');
const assetUrl20 = require('vectors/signup/candidates-illustration.svg');
